/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  
  function watchNavigationToggle() {
    var ww = $(window).width();
    
    if(ww < 1142 && $('.brand-club').is(':hidden')) {
      $('.navbar-toggle').show();
      
      $('#navigation').addClass('collapse').css('max-height', $(window).height() - 70);
    }
    else {
      $('.navbar-toggle').hide();
      
      $('#navigation').removeClass('collapse').css('height', 'auto');
    }
  }
  
  function watchHomeNavigation() {
    var banner = $('#homepage-banner'), 
            scroll = $(window).scrollTop();
    
    if(scroll > (banner.height()/2 - 43)) {
      $('#header').removeClass('over-banner');
    }
    else {
      $('#header').addClass('over-banner');
    }
    
    if(scroll > (banner.height() - 86)) {
      $('#header').removeClass('dark');
    }
    else {
      $('#header').addClass('dark');
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        watchNavigationToggle();
        
        $(window).on('load scroll resize', function() {
          watchNavigationToggle();
        });
        
        if($('.thumbs-slideshow').length) {
          $('.big-image-slideshow').slick({
            asNavFor: '.thumbs-slideshow',
            fade: true,
            arrows: false,
            dots: false
          });
          
          $('.thumbs-slideshow').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.big-image-slideshow',
            arrows: false,
            dots: false,
            centerMode: true,
            centerPadding: '0px',
            focusOnSelect: true,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
              {
                breakpoint: 1142,
                settings: {
                  slidesToShow: 6
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 6
                }
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 320,
                settings: {
                  slidesToShow: 3
                }
              }
            ]
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        watchHomeNavigation();
        
        $(window).on('load scroll resize', function() {
          watchHomeNavigation();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
